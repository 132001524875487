.App {
  text-align: center;
}

.background-animate {
  background-size: 400%;
  -webkit-animation: AnimationName 6s ease infinite;
  -moz-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

/* scroll to the section smoothly */
html {
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}

/* Disable focus outline */
.image-slider img-comparison-slider {
  outline: none;
}

